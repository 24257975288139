import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CtaV9 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <section >
              {/* <div className="container text-center">
                <h3>Our Business Advisors <br />
                  Always Help You</h3>
                <Link to="/service" className="thm-btn">Read More <i className="fa fa-angle-double-right" /></Link>
              </div> */}
            </section>

        }
	}

export default CtaV9