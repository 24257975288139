import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TestimonialOne extends Component {

	componentDidMount() {

		const $ = window.$;

		if ($('.js-tilt').length) {
			$('.js-tilt').tilt({
				maxTilt: 20,
				perspective: 700,
				glare: true,
				maxGlare: .3
			})
		}

		if ($('.thm__owl-carousel').length) {
			$('.thm__owl-carousel').each(function () {
				var Self = $(this);
				var carouselOptions = Self.data('options');
				var carouselPrevSelector = Self.data('carousel-prev-btn');
				var carouselNextSelector = Self.data('carousel-next-btn');
				var thmCarousel = Self.owlCarousel(carouselOptions);
				if (carouselPrevSelector !== undefined) {
					$(carouselPrevSelector).on('click', function () {
						thmCarousel.trigger('prev.owl.carousel');
						return false;
					});
				}
				if (carouselNextSelector !== undefined) {
					$(carouselNextSelector).on('click', function () {
						thmCarousel.trigger('next.owl.carousel');
						return false;
					});
				}
			});
		}
	}

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'

		return <section className="testimonials-one">
			<div className="container">
				<div className="testimonials-one__top">
					<div className="block-title text-left">
						{/* Our Simplify Workflow */}
						{/* <p><span>Clients Say</span></p> */}
						<h3>Our Simplify Workflow</h3>
						<div className="block-title__line" />{/* /.block-title__line */}
					</div>{/* /.block-title */}
					<div className="testimonials-one__carousel-btn__wrap">
						<a href="#" className="testimonials-one__carousel-btn-left"><i className="fa fa-angle-double-left" /></a>
						<a href="#" className="testimonials-one__carousel-btn-right"><i className="fa fa-angle-double-right" /></a>
					</div>{/* /.testimonials-one__carousel-btn__wrap */}
				</div>{/* /.testimonials-one__top */}
				{/* Understanding Business Requirements */}
				<div className="testimonials-one__arrow-decor-wrap">
					<i className="fa fa-angle-right" />
					<i className="fa fa-angle-right" />
					<i className="fa fa-angle-right" />
					<i className="fa fa-angle-right" />
				</div>{/* /.testimonials-one__arrow-decor-wrap */}
				<div className="testimonials-one__carousel owl-carousel thm__owl-carousel owl-theme" data-carousel-prev-btn=".testimonials-one__carousel-btn-left" data-carousel-next-btn=".testimonials-one__carousel-btn-right" data-options="{&quot;loop&quot;: true, &quot;margin&quot;: 65, &quot;stagePadding&quot;: 50, &quot;items&quot;: 3, &quot;smartSpeed&quot;: 700, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;nav&quot;: false, &quot;dots&quot;: false, &quot;responsive&quot;: { &quot;0&quot;: { &quot;items&quot;: 1, &quot;stagePadding&quot;: 20 }, &quot;1199&quot;: { &quot;items&quot;: 2 }, &quot;1200&quot;: { &quot;items&quot;: 3 } }}">
					<div className="item">
						<div className="testimonials-one__single">
							<div className="testimonials-one__image">
								{/* <img src={publicUrl+"assets/images/testimonial/s/testimonial-1-1.jpg"} alt={ imagealt } /> */}
							</div>{/* /.testimonials-one__image */}
							<div className="testimonials-one__content">
								<div className="service-one__top-content">
									<h3>Understanding Business Requirements</h3>
								</div>{/* /.service-one__top-content */}
								<p>As a leading ERP solution company,we deep understanding of what exactly you expect from the design and deployment of the ERP software solution</p>
								{/* <h3>Pearl Campbell</h3> */}
								{/* <span>CEO &amp; Founder</span> */}
								<i className="fa fa-quote-right testimonials-one__qoute-icon" />
							</div>{/* /.testimonials-one__content */}
						</div>{/* /.testimonials-one__single */}
					</div>{/* /.item */}
					<div className="item">
						<div className="testimonials-one__single">
							<div className="testimonials-one__image">
								{/* <img src={publicUrl+"assets/images/testimonials/testimonial-1-2.jpg"} alt={ imagealt } /> */}
							</div>{/* /.testimonials-one__image */}
							<div className="testimonials-one__content">
							<div className="service-one__top-content">
									<h3>Crafting a Comprehensive Design Plan</h3>
								</div>
								<p>We allow to have a detailed blueprint with details related to data migration,infrastructure development,solution integration with existing platforms, and so on</p>
								{/* <h3>Landon Anderson</h3> */}
								{/* <span>CEO &amp; Founder</span> */}
								<i className="fa fa-quote-right testimonials-one__qoute-icon" />
							</div>{/* /.testimonials-one__content */}
						</div>{/* /.testimonials-one__single */}
					</div>{/* /.item */}
					<div className="item">
						<div className="testimonials-one__single">
							<div className="testimonials-one__image">
								{/* <img src={publicUrl+"assets/images/testimonials/testimonial-1-3.jpg"} alt={ imagealt } /> */}
							</div>{/* /.testimonials-one__image */}
							<div className="testimonials-one__content">
							<div className="service-one__top-content">
									<h3>Building &amp; Deploying The ERP Software Solutions</h3>
								</div>
								<p>Your business capabilities with our end-to-end proficiency in building and deploying ERP software solutions, ensuring optimal performance and streamlined processes</p>
								{/* <h3>Daniel Howell</h3> */}
								{/* <span>CEO &amp; Founder</span> */}
								<i className="fa fa-quote-right testimonials-one__qoute-icon" />
							</div>{/* /.testimonials-one__content */}
						</div>{/* /.testimonials-one__single */}
					</div>{/* /.item */}
					<div className="item">
						<div className="testimonials-one__single">
							<div className="testimonials-one__image">
								{/* <img src={publicUrl + "assets/images/testimonials/testimonial-1-1.jpg"} alt={imagealt} /> */}
							</div>{/* /.testimonials-one__image */}
							<div className="testimonials-one__content">
							<div className="service-one__top-content">
									<h3>Offering Client-First Support</h3>
								</div>
								<p>Delivering client-first support to ensure your success, we prioritize your needs with dedicated assistance, expert guidance, and responsive service.
								Web Development Languages</p>
								{/* <h3>Jay Houston</h3> */}
								{/* <span>CEO &amp; Founder</span> */}
								<i className="fa fa-quote-right testimonials-one__qoute-icon" />
							</div>{/* /.testimonials-one__content */}
						</div>{/* /.testimonials-one__single */}
					</div>{/* /.item */}
				
				
				</div>{/* /.testimonials-one__carousel owl-carousel thm__owl-carousel owl-theme */}
			</div>{/* /.container */}
		</section>


	}
}

export default TestimonialOne