import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceDetails extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return	<section className="service-two service-two__service-page  go-top">
			  <div className="container">
				<div className="row">
				  <div className="col-lg-5">
					<div className="service-two__block">
					  <div className="block-title-two text-left">
						<p>Our Services</p>
						<h3>We Offer Exclusive <br /> Service For You</h3>
					  </div>{/* /.block-title-two */}
					  <p>we provide tailored solutions to meet your business needs. From GST-compliant billing to smart accounting, our exclusive services ensure seamless financial management. Experience secure, efficient, and user-friendly software designed to help you focus on what truly matters—growing your business! </p>
					  {/* <ul className="list-unstyled video-one__list">
						<li>
						  <i className="far fa-check" />
						  Smashing Book 6 Is Here New Frontiers In Web Design
						</li>
						<li>
						  <i className="far fa-check" />
						  Introduction To Animation And The iMessage App Store With Shruggie
						</li>
						<li>
						  <i className="far fa-check" />
						  Get Your Mobile Site Ready For The 2018 Holiday ways goods
						</li>
						<li>
						  <i className="far fa-check" />
						  Making Distributed Product Teams Work More Efficiently other
						</li>
					  </ul> */}
					  {/* <Link to="/service-v2" className="thm-btn">View More <i className="fa fa-angle-double-right" /></Link> */}
					  {/* /.thm-btn */}
					</div>{/* /.service-two__block */}
				  </div>{/* /.col-lg-5 */}
				  <div className="col-lg-7">
					<div className="service-two__box-wrap">
					  <div className="row">
						<div className="col-md-6">
						  <div className="service-two__single js-tilt">
							<div className="service-two__single-image"  />
							{/* /.service-two__single-image */}
							<div className="service-two__single-inner">
							  <i className="fal fa-hand-holding-usd" />
							  <h3><Link to="/service-details">ERP Consulting Services</Link></h3>
							  <p>Transform your business with our expert ERP consulting services, optimizing operations and enhancing efficiency for sustained growth and success.</p>
							  <Link to="/service-details" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
							  {/* /.service-two__link */}
							</div>{/* /.service-two__single-inner */}
						  </div>{/* /.service-two__single */}
						</div>{/* /.col-md-6 */}
						<div className="col-md-6">
						  <div className="service-two__single js-tilt">
							<div className="service-two__single-image"  />
							{/* /.service-two__single-image */}
							<div className="service-two__single-inner">
							  <i className="fal fa-umbrella-beach" />
							  <h3><Link to="/service-details">Custom ERP Solutions</Link></h3>
							  <p>we develop robust and scalable business ERP systems that offer key data insights across multiple plateforms-web,mobile,and on-premise.</p>
							  <Link to="/service-details" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
							  {/* /.service-two__link */}
							</div>{/* /.service-two__single-inner */}
						  </div>{/* /.service-two__single */}
						</div>{/* /.col-md-6 */}
						<div className="col-md-6">
						  <div className="service-two__single js-tilt">
							<div className="service-two__single-image"  />
							{/* /.service-two__single-image */}
							<div className="service-two__single-inner">
							  <i className="fal fa-paper-plane" />
							  <h3><Link to="/service-details">ERP Implementation Services</Link></h3>
							  <p>Our Erp services team is uniquely equipped to take care of complete deployment of your custom networks,servers, and data management solutions while maintaining data integrity.</p>
							  <Link to="/service-details" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
							  {/* /.service-two__link */}
							</div>{/* /.service-two__single-inner */}
						  </div>{/* /.service-two__single */}
						</div>{/* /.col-md-6 */}
						<div className="col-md-6">
						  <div className="service-two__single js-tilt">
							<div className="service-two__single-image"  />
							{/* /.service-two__single-image */}
							<div className="service-two__single-inner">
							  <i className="fal fa-chart-network" />
							  <h3><Link to="/service-details">Managed ERP Services</Link></h3>
							  <p>Our experts ensure continuous monitoring,management and support of your ERP system,thus identifying,resolving,and preventing issues.</p>
							  <Link to="/service-details" className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></Link>
							  {/* /.service-two__link */}
							</div>{/* /.service-two__single-inner */}
						  </div>{/* /.service-two__single */}
						</div>{/* /.col-md-6 */}
					  </div>{/* /.row */}
					</div>{/* /.service-two__box-wrap */}
				  </div>{/* /.col-lg-7 */}
				</div>{/* /.row */}
			  </div>
			</section>

        }
}

export default ServiceDetails