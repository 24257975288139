// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

// class Contact extends Component {

//     render() {

//         let publicUrl = process.env.PUBLIC_URL+'/'

//     return <div>
// 		  <section className="contact-two">
// 		    <div className="container">
// 		      <div className="row">
// 		        <div className="col-lg-5">
// 		          <div className="contact-two__content">
// 		            <div className="contact-two__block">
// 		              <h3>Don't Hesitate To Contact Us</h3>
// 		              <p>Sed ut perspiciatis unde omnis iste natus erro voluptat accusantium doloremque laudantium
// 		                totam rem aperiam eaque</p>
// 		            </div>
// 		            <ul className="list-unstyled contact-two__info-list">
// 		              <li>
// 		                <div className="contact-two__info-list-title">
// 		                  <i className="far fa-map-marked" />
// 		                  Address :
// 		                </div>
// 		                <p>Royal Square, 212, ROB Bridge, nr. Utran, <br />Uttran, Surat, Gujarat | 394105 India </p>
// 		              </li>
// 		              <li>
// 		                <div className="contact-two__info-list-title">
// 		                  <i className="far fa-phone" />
// 		                  Call Us :
// 		                </div>
// 		                <p><a href="tel:+918511272311">+91 8511272311</a></p>
// 		              </li>
// 		              <li>
// 		                <div className="contact-two__info-list-title">
// 		                  <i className="far fa-envelope" />
// 		                  Email Us :
// 		                </div>
// 		                <p><a href="mailto:contadorerp@gmail.com">contadorerp@gmail.com</a></p>
// 		              </li>
// 		            </ul>
// 		          </div>
// 		        </div>
// 		        <div className="col-lg-7">
// 		          <form action="#" className="contact-two__form">
// 		            <div className="contact-two__block">
// 		              <h3>Get In Touch</h3>
// 		              <p>Sed ut perspiciatis unde omnis iste natus erro voluptat accusantium <br />doloremque laudantium
// 		                totam rem aperiam eaque</p>
// 		            </div>
// 		            <div className="row">
// 		              <div className="col-md-6">
// 		                <input type="text" placeholder="Full Name" />
// 		              </div>
// 		              <div className="col-md-6">
// 		                <input type="text" placeholder="Email Address" />
// 		              </div>
// 		              <div className="col-md-12">
// 		                <input type="text" placeholder="Subject" />
// 		              </div>
// 		              <div className="col-md-12">
// 		                <textarea name="message" placeholder="Message" defaultValue={""} />
// 		                <button type="submit" className="thm-btn contact-two__btn">Send Message <i className="fa fa-angle-double-right" /></button>{/* /.thm-btn contact-two__btn */}
// 		              </div>
// 		            </div>
// 		          </form>
// 		        </div>
// 		      </div>
// 		    </div>
// 		  </section>
// 		  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd" className="google-map__contact" allowFullScreen /> */}
// 		</div>

//         }
// }

// export default Contact




import React, { Component } from 'react';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      subject: '',
      message: ''
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    
    const { fullName, email, subject, message } = this.state;
    
    const phoneNumber = "918511272311"; // Replace with your WhatsApp number
    const whatsappMessage = `Hello, I am interested in contacting you.%0A%0A
      *Full Name:* ${fullName}%0A
      *Email:* ${email}%0A
      *Subject:* ${subject}%0A
      *Message:* ${message}`;

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${whatsappMessage}`;

    window.open(whatsappURL, "_blank");
  };

  render() {
    return (
      <section className="contact-two">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="contact-two__content">
                <div className="contact-two__block">
                  <h3>Don't Hesitate To Contact Us</h3>
                  <p>Feel free to reach out to us for any inquiries or assistance.</p>
                </div>
                <ul className="list-unstyled contact-two__info-list">
                  <li>
                    <div className="contact-two__info-list-title">
                      <i className="far fa-map-marked" />
                      Address :
                    </div>
                    <p>Royal Square, 137, ROB Bridge, nr. Utran, <br />Uttran, Surat, Gujarat | 394105 India</p>
                  </li>
                  <li>
                    <div className="contact-two__info-list-title">
                      <i className="far fa-phone" />
                      Call Us :
                    </div>
                    <p><a href="tel:+918511272311">+91 8511272311</a></p>
                  </li>
                  <li>
                    <div className="contact-two__info-list-title">
                      <i className="far fa-envelope" />
                      Email Us :
                    </div>
                    <p><a href="mailto:contadorerp@gmail.com">contadorerp@gmail.com</a></p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7">
              <form onSubmit={this.handleSubmit} className="contact-two__form">
                <div className="contact-two__block">
                  <h3>Get In Touch</h3>
                  <p>Fill out the form and we will get back to you as soon as possible.</p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <input 
                      type="text" 
                      name="fullName" 
                      placeholder="Full Name" 
                      value={this.state.fullName} 
                      onChange={this.handleChange} 
                      required 
                    />
                  </div>
                  <div className="col-md-6">
                    <input 
                      type="email" 
                      name="email" 
                      placeholder="Email Address" 
                      value={this.state.email} 
                      onChange={this.handleChange} 
                      required 
                    />
                  </div>
                  <div className="col-md-12">
                    <input 
                      type="text" 
                      name="subject" 
                      placeholder="Subject" 
                      value={this.state.subject} 
                      onChange={this.handleChange} 
                      required 
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea 
                      name="message" 
                      placeholder="Message" 
                      value={this.state.message} 
                      onChange={this.handleChange} 
                      required 
                    />
                    <button type="submit" className="thm-btn contact-two__btn">
                      Send Message <i className="fa fa-angle-double-right" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
