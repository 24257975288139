import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class PricingV4 extends Component {

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    return <section className="pricing-one pricing-one__pricing-page">
      <div className="container">
        <div className="pricing-one__block">
          <div className="block-title-two text-left">
            <p>get a quote</p>
            <h3>Don't Hesitate To Contact <br /> With Us Get A Call</h3>
          </div>{/* /.block-title-two */}
          {/* <div className="pricing-one__btn-block">
              <ul className="list-unstyled nav nav-tabs">
                <li className="nav-item">
                  <a href="#monthly" className="nav-link active" data-toggle="tab">Monthly</a>
                </li>
                <li className="nav-item">
                  <a href="#yearly" className="nav-link " data-toggle="tab">Yearly</a>
                </li>
              </ul>
            </div> */}
        </div>{/* /.pricing-one__block */}
        <div className="tab-content">
          <div className="tab-pane show active  animated fadeInUp" id="monthly">
            <div className="row">
              <div className="col-lg-4">
                <div className="pricing-one__single">
                  <div className="pricing-one__inner">
                    {/* <img src={publicUrl+"assets/images/shapes/pricing-i-1-1.png"} alt={ imagealt } /> */}
                    <h3>Trial</h3>
                    <div className="pricing-one__top">
                      <p><span>₹</span>0</p>
                    </div>
                    <ul className="pricing-one__list list-unstyled">
                      <li><i className="far fa-check" />All features of professional plan</li>
                    </ul>
                    <a
                      href={`https://wa.me/918511272311?text=${encodeURIComponent("Hello, I am interested in the Trial Plan. Can you provide more details?")}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="thm-btn pricing-one__btn"
                    >
                      Choose Plan <i className="fa fa-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
  <div className="pricing-one__single">
    <div className="pricing-one__inner">
      <h3>Basic</h3>
      <div className="pricing-one__top">
        <p><span>₹</span>4500</p>
      </div>
      <ul className="pricing-one__list list-unstyled">
        <li><i className="far fa-check" />Manage customers & vendors</li>
        <li><i className="far fa-check" />Track your incomes & expenses</li>
        <li><i className="far fa-check" />Manage your bank accounts</li>
        <li><i className="far fa-check" />Generate financial reports</li>
        <li><i className="far fa-check" />Unlimited user access</li>
        <li><i className="far fa-check" />Email support</li>
      </ul>
      <a 
        href={`https://wa.me/918511272311?text=${encodeURIComponent("Hello, I'm interested in the Basic Plan (₹4500). I would love to know how it can help me manage my business more efficiently. Could you share more details?")} `} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="thm-btn pricing-one__btn"
      >
        Get Started Now <i className="fa fa-angle-double-right" />
      </a>
    </div>
  </div>
</div>

              <div className="col-lg-4">
                <div className="pricing-one__single">
                  <div className="pricing-one__inner">
                    {/* <img src={publicUrl+"assets/images/shapes/pricing-i-1-3.png"} alt="pricing" /> */}
                    <h3>Premium</h3>
                    <div className="pricing-one__top">
                      <p><span>₹</span>15000</p>
                    </div>
                    <ul className="pricing-one__list list-unstyled">
                      <li><i className="far fa-check" />Manage customers & vendors</li>
                      <li><i className="far fa-check" />Track your incomes &amp; expenses</li>
                      <li><i className="far fa-check" />Manage your bank accounts</li>
                      <li><i className="far fa-check" />Email support</li>
                      <li><i className="far fa-check" />Create professional invoices</li>
                      <li><i className="far fa-check" />Unlimited user access</li>
                      <li><i className="far fa-check" />Generate financial reports</li>
                      <li><i className="far fa-check" />Send invoices via Email</li>
                      <li><i className="far fa-check" />Manage inventory</li>
                      <li><i className="far fa-check" />GST Reports: GSTR1 & GSTR3B</li>
                    </ul>
                    <a href={`https://wa.me/918511272311?text=${encodeURIComponent("Hello, I'm interested in the Basic Plan (₹15000). I would love to know how it can help me manage my business more efficiently. Could you share more details?")} `} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="thm-btn pricing-one__btn">Choose Plan <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn pricing-one__btn */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane animated fadeInUp " id="yearly">
            <div className="row">
              <div className="col-lg-4">
                <div className="pricing-one__single">
                  <div className="pricing-one__inner">
                    <img src={publicUrl + "assets/images/shapes/pricing-i-1-1.png"} alt={imagealt} />
                    <div className="pricing-one__top">
                      <h3>Startup</h3>
                      <p><span>$</span>69.99</p>
                    </div>
                    <ul className="pricing-one__list list-unstyled">
                      <li><i className="far fa-check" />50GB Bandwidth</li>
                      <li><i className="far fa-check" />Business &amp; Financ Analysing</li>
                      <li><i className="far fa-check" />24 hour support</li>
                      <li><i className="far fa-check" />Customer Managemet</li>
                    </ul>
                    <a href="#" className="thm-btn pricing-one__btn">Choose Plan <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn pricing-one__btn */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="pricing-one__single">
                  <div className="pricing-one__inner">
                    <span className="pricing-one__corner-ribbon">Best</span>

                    <img src={publicUrl + "assets/images/shapes/pricing-i-1-2.png"} alt={imagealt} />
                    <div className="pricing-one__top">
                      <h3>Regular</h3>
                      <p><span>$</span>79.99</p>
                    </div>
                    <ul className="pricing-one__list list-unstyled">
                      <li><i className="far fa-check" />50GB Bandwidth</li>
                      <li><i className="far fa-check" />Business &amp; Financ Analysing</li>
                      <li><i className="far fa-check" />24 hour support</li>
                      <li><i className="far fa-check" />Customer Managemet</li>
                    </ul>
                    <a href="#" className="thm-btn pricing-one__btn">Choose Plan <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn pricing-one__btn */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="pricing-one__single">
                  <div className="pricing-one__inner">
                    <img src={publicUrl + "assets/images/shapes/pricing-i-1-3.png"} alt={imagealt} />
                    <div className="pricing-one__top">
                      <h3>Premium</h3>
                      <p><span>$</span>99.99</p>
                    </div>
                    <ul className="pricing-one__list list-unstyled">
                      <li><i className="far fa-check" />50GB Bandwidth</li>
                      <li><i className="far fa-check" />Business &amp; Financ Analysing</li>
                      <li><i className="far fa-check" />24 hour support</li>
                      <li><i className="far fa-check" />Customer Managemet</li>
                    </ul>
                    <a href="#" className="thm-btn pricing-one__btn">Choose Plan <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn pricing-one__btn */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  }
}

export default PricingV4