import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutPage extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'

		return <section className="service-two service-two__service-page service-two__about-page  go-top">
			<div className="container">
				{/* <div className="row">
					<div className="col-lg-6">
						<div className="service-two__image">
							<img src={publicUrl + "assets/images/resources/about-page-moc-1.png"} alt={imagealt} />
						</div>
					</div>

				</div> */}
				<div className="col-lg-12">
					<div className="service-two__block">
						<div className="block-title-two text-left">
							{/* <p>about us</p> */}
							{/* <h3>Exclusive Agency To Provide Soluation</h3> */}
						</div>{/* /.block-title-two */}

						<ul className="list-unstyled video-one__list">
							<li>
								{/* <i className="far fa-check" /> */}
								<h4>Contador is an easy-to-use online GST accounting software.
									It has been developed and conceptualized by a team having 15+ years of
									experience in developing accounting software. Since its launch in 2017,
									Contador has provided accounting solutions to over 9000 customers. </h4>
							</li>
							<li>
								{/* <i className="far fa-check" /> */}
								<h4>
									We strive to develop genuine and meaningful relationships with our
									clients to solve their most pressing problems. Unlike any other accounting software,
									Contador has been developed in such a way that any layman can be onboarded without
									any prior experience and training. We are passionate about helping every individual or
									business with their accounting need.
								</h4>
							</li>
							<li>
								{/* <i className="far fa-check" /> */}
								<h4>
									Our mission is to provide the most advanced and easiest-to-use accounting solution to our customers so our customers can focus on their own businesses.
								</h4>
							</li>
						</ul>
						{/* <Link to="/service" className="thm-btn">Read More <i className="fa fa-angle-double-right" /></Link> */}
					</div>
				</div>
			</div>
		</section>



	}
}

export default AboutPage