import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class WhyChooseUs extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="service-one go-top">
          <div className="container">
          {/*   */}
            <div className="block-title text-center">
              <p><span>Why Like Us</span></p>
              <h3>Boost Your Business Efficiency with
               <br />
               Advanced GST Billing Software</h3>
              <div className="block-title__line" />{/* /.block-title__line */}
            </div>{/* /.block-title */}
            <div className="row high-gutter  go-top">
              
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/service-i-1.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    {/* Secure & Reliable */}
                    <div className="service-one__top-content">
                      <h3><Link to="/service-details">Secure &amp; Reliable</Link></h3>
                      {/* <p>Improve Business</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>Advanced security ensures your financial data is safe.</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4">
                <div className="service-one__single">
                {/* Multi-User Support */}
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/service-i-2.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3><Link to="/service-details">Multi-User Support
                        </Link></h3>
                      {/* <p>SEO Optimization</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>Collaborate with your team efficiently.</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4">
              {/* Contador provides 30 days free trial with all features, and it starts from just ₹4500/per year. */}
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/service-i-3.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3><Link to="/service-details"> Affordable Pricing
                      </Link></h3>
                      {/* <p>Web Soluations</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>Contador provides 30 days free trial with all features, and it starts from just ₹4500/per year.</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}



            </div>{/* /.row */}
          </div>{/* /.container */}
        </section>

        }
}

export default WhyChooseUs