import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

class FunfactV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <section className="funfact-one funfact-one__service-page">
			  {/* <div className="container">
			    <div className="inner-container wow fadeInUp" data-wow-duration="1500ms">
			      <div className="row">
			        <div className="col-lg-3 col-md-6">
			          <div className="funfact-one__single">
			            <div className="funfact-one__icon">
			              <i className="far fa-mug-hot" />
			            </div>
			            <h3><span className="counter">2500</span>+</h3>
			            <p>Project</p>
			          </div>
			        </div>
			        <div className="col-lg-3 col-md-6">
			          <div className="funfact-one__single">
			            <div className="funfact-one__icon">
			              <i className="far fa-heart" />
			            </div>
			            <h3><span className="counter">2960</span>+</h3>
			            <p>Satisfied Clients</p>
			          </div>
			        </div>
			        <div className="col-lg-3 col-md-6">
			          <div className="funfact-one__single">
			            <div className="funfact-one__icon">
			              <i className="far fa-award" />
			            </div>
			            <h3><span className="counter">5620</span>
			            </h3>
			            <p>Win Awards</p>
			          </div>
			        </div>
			        <div className="col-lg-3 col-md-6">
			          <div className="funfact-one__single">
			            <div className="funfact-one__icon">
			              <i className="far fa-user-alt" />
			            </div>
			            <h3><span className="counter">365</span>+</h3>
			            <p>Team Member</p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div> */}
			</section>


        }
}

export default FunfactV5